<template>
  <div class="banner">
    <a
      v-for="(item, index) in bannerArr"
      :key="index"
      :href="item.linkUrl"
      :class="['banner-item', { cur: banner_currentId === index }]"
      target="_blank"
    >
      <img class="banner-image" :src="item.url" alt="" />
    </a>
    <div class="tabbox">
      <ul>
        <li
          v-for="(item, index) in banner_text_list"
          :key="index"
          :class="{ active: index == banner_currentId }"
        >
          <a
            :href="item.url"
            v-text="item.text"
            @mouseenter="banner_change(index)"
            @mouseout="reAutoplay"
            target="_blank"
          ></a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getBanner } from "network/home";

export default {
  name: "Banner",
  data() {
    return {
      bannerArr: [
        {
          text: "趣味JAVA教程",
          url: require("@/assets/images/banner/banner6.jpg"),
          linkUrl: "http://video.mobiletrain.org/video/1/662/"
        },
        // {
        //   text: "Java分布式架构",
        //   url: require("@/assets/images/banner/banner1.png"),
        // },
        {
          text: "前端Node.js入门到实战",
          url: require("@/assets/images/banner/banner2.png"),
          linkUrl: "https://video.mobiletrain.org/video/3/177/"
        },
        {
          text: "Hudi数据湖",
          url: require("@/assets/images/banner/banner3.png"),
          linkUrl: "https://video.mobiletrain.org/video/12/296/"
        },
        {
          text: "Python快速搞定Excel数据分析",
          url: require("@/assets/images/banner/banner4.png"),
          linkUrl: "https://video.mobiletrain.org/video/4/368/"
        },
        {
          text: "玩转商业插画设计",
          url: require("@/assets/images/banner/banner5.png"),
          linkUrl: "https://video.mobiletrain.org/video/7/256/"
        },
      ],
      banner_text_list: [
        {
          text: "Java分布式架构",
          url: "/video/1/281" + this.addHrefSearch(),
          alt: "Java教程",
        },
        {
          text: "前端Node.js入门到实战",
          url: "/video/3/177" + this.addHrefSearch(),
          alt: "前端教程",
        },
        {
          text: "Hudi数据湖",
          url: "/video/12/296" + this.addHrefSearch(),
          alt: "大数据教程",
        },
        {
          text: "Python快速搞定Excel数据分析",
          url: "/video/4/368" + this.addHrefSearch(),
          alt: "Python教程",
        },
        {
          text: "玩转商业插画设计",
          url: "/video/7/256" + this.addHrefSearch(),
          alt: "UI教程",
        },
      ],
      banner_currentId: 0,
      banner_change_interval: setInterval(this.banner_autoPlay, 4000),
    };
  },
  methods: {
    async getBanner() {
      const res = await getBanner();
      this.bannerArr = res.data;
    },
    banner_change(id) {
      clearInterval(this.banner_change_interval);
      this.banner_change_interval = null;
      this.banner_currentId = id;
    },
    reAutoplay() {
      this.banner_change_interval = setInterval(this.banner_autoPlay, 4000);
    },
    banner_autoPlay() {
      this.banner_currentId += 1;
      if (this.banner_currentId >= this.bannerArr.length)
        this.banner_currentId = 0;
    },
  },
  created() {
    // this.getBanner();
    this.banner_change_interval;
  },
};
</script>

<style scoped lang='less'>
.banner {
  overflow: hidden;
  position: relative;
  height: 360px;
  .banner-item {
    display: none;
    min-width: 1920px;
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    &.cur {
      display: block;
    }
  }
  .banner-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .tabbox {
    position: absolute;
    top: 0;
    right: 7%; // 130px
    z-index: 10;
    width: 340px;
    height: 360px;
    padding: 55px 53px;
    background: rgba(0, 0, 0, 0.3);
    ul {
      li {
        height: 20px;
        line-height: 20px;
        margin-bottom: 40px;
        max-width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
        &.active {
          font-size: 20px;
          position: relative;
          &::before {
            content: "";
            display: inline-block;
            position: absolute;
            left: -20px;
            top: 5px;
            width: 0;
            height: 0;
            border: 5px solid transparent;
            border-right: 6px solid white;
          }
        }
        a {
          display: inline-block;
          color: #fff;
          white-space: nowrap;
          width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}
</style>