<template>
  <div class="home__wrapper">
    <banner />

    <div class="inner-box">
      <p class="title">
        <strong>核心师资</strong>
        <span>实力派授课导师，10年+开发经验倾囊相授</span>
      </p>
      <teacher-list />
    </div>

    <keep-alive>
      <router-view v-if="$store.state.sj.subjectArr.length" />
    </keep-alive>

    <div class="toolsControl">
      <div class="tools">
        <source-code></source-code>
        <article-list></article-list>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "./childComps/Banner.vue";
import TeacherList from "./childComps/TeacherList.vue";
import sourceCode from "./childComps/SourceCode.vue";
import ArticleList from "./childComps/ArticleList.vue";

export default {
  name: "Home",
  components: {
    Banner,
    TeacherList,
    sourceCode,
    ArticleList,
  },
  data() {
    return {};
  },

  methods: {},
  mounted() {},
  created() {},
};
</script>

<style lang='less'>
.inner-box {
  width: @MWidth;
  margin: 0 auto;
  padding-top: 40px;
  // overflow: hidden;
  .title {
    display: flex;
    align-items: flex-end;
    padding-bottom: 8px;
    strong {
      font-size: 20px;
      color: #000000;
      font-weight: bold;
    }

    span {
      font-size: 12px;
      color: #666666;
      margin-left: 10px;
    }
  }
}

.toolsControl {
  width: @MWidth;
  margin: 0 auto;
  margin-top: 60px;
}

.tools {
  display: flex;
  width: @MWidth;
  margin: 0 auto;
  margin-bottom: 54px;
  .toolsControl-content {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
  }
  .title {
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;

    h2 {
      font-size: 20px;
      color: #000000;
      /*         overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; */
      text-align: left;
    }

    a {
      font-size: 16px;
      color: #018dd6;
    }
  }
}
</style>

