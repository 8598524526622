<template>
  <div class="article">
    <div class="title">
      <h2>技术文章</h2>
      <a :href="'/articleList' + addHrefSearch()">More ></a>
    </div>
    <div class="toolsControl-content">
      <a
        v-for="(item, index) in articleList"
        class="articl-list"
        :class="{ borderBottom: index === articleList.length - 1 }"
        target="_blank"
        :href="'/article/' + item.id + addHrefSearch()"
      >
        <div class="description">
          <h3 v-html="item.articleTitle"></h3>
          <p class="message" v-html="spliceMesage(item.content)"></p>
        </div>
        <div class="bottom">
          <div class="seenumber">
            <img src="~@/assets/images/icons/eye@3x.png" alt="Python视频教程" />
            <span v-html="item.lookNum"></span>
          </div>
          <div v-html="item.createTime.slice(0, 10)" class="timer"></div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { getArticleList } from "network/home";

export default {
  name: "ArticleList",
  data() {
    return {
      articleList: [],
    };
  },
  methods: {
    async getArticleList() {
      const res = await getArticleList();
      this.articleList = res.data;
    },
    spliceMesage(str) {
      return this.removeHTMLTag(str.slice(0, 300));
    },
  },
  created() {
    this.getArticleList();
  },
};
</script>

<style scoped lang='less'>
.article {
  margin-left: 32px;
  width: 571px;

  .articl-list {
    // border-bottom: 1px dashed #cccccc;
    height: 100px;
    box-sizing: border-box;
    cursor: pointer;
    display: block;

    &:hover {
      background: #f5fcff;
    }

    .description {
      h3 {
        font-weight: bold;
        color: #000000;
        font-size: 14px;
        /*             overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical; */
        // max-width: 328px;
        padding-top: 20px;
        padding-bottom: 8px;
      }

      .message {
        font-size: 12px;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;

    .seenumber {
      display: flex;
      align-items: center;
      color: #018dd6;
      font-size: 12px;

      img {
        width: 18px;
        height: 11px;
        margin-right: 5px;
      }
    }

    .timer {
      color: #666666;
      font-size: 12px;
    }
  }
}
</style>