<template>
  <div class="source-code">
    <div class="title">
      <h2>工具源码</h2>
      <a :href="'/download' + addHrefSearch()">More ></a>
    </div>
    <div class="toolsControl-content">
      <div
        v-for="(item, index) in sourceCodeList"
        class="code"
        :class="{ borderBottom: index === sourceCodeList.length - 1 }"
      >
        <img
          src="~@/assets/images/code@3x.png"
          v-if="item.type === 2"
          alt="大数据视频教程"
        />
        <img
          src="~@/assets/images/tool@3x.png"
          v-if="item.type === 1"
          alt="Python视频教程"
        />
        <div class="wrap">
          <div class="description">
            <h3 v-html="item.codeName"></h3>
            <p v-html="item.codeDesc"></p>
          </div>
          <div @click="downloadResource(item)" class="download">立即下载</div>
        </div>
      </div>
    </div>

    <el-dialog
      :lock-scroll="false"
      custom-class="joinus"
      :visible.sync="dialogVisible"
      width="835px"
    >
      <div class="header">
        <div class="steps">
          <span>1</span>
          <div class="des">
            <p>第一步</p>
            <p>扫码</p>
          </div>
        </div>
        <div class="steps line"></div>
        <div class="steps">
          <span>2</span>
          <div class="des">
            <p>第二步</p>
            <p>选择</p>
          </div>
        </div>
        <div class="steps line"></div>
        <div class="steps">
          <span>3</span>
          <div class="des">
            <p>第3步</p>
            <p>获取</p>
          </div>
        </div>
      </div>
      <div class="direction">
        <div class="code">
          <img :src="qrCodeurl" alt="" style="margin-right: 0" />
        </div>
        <div class="item">
          <div class="content">
            <p>关注微信公众号</p>
            <p v-html="officialName"></p>
          </div>
        </div>
        <img src="~@/assets/images/icons/arrow.png" alt="" />
        <div class="item">
          <div class="content">
            <p></p>
            <p>选择所需学科</p>
            <!-- <p v-html="keyName"></p> -->
          </div>
        </div>
        <img src="~@/assets/images/icons/arrow.png" alt="" />
        <div class="item">
          <div class="content">
            <p></p>
            <p>获取全套课程资料</p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSourceCodeList } from "network/home";

export default {
  name: "SourceCode",
  data() {
    return {
      sourceCodeList: [],
      keyName: "",
      qrCodeurl: "",
      officialName: "",
      dialogVisible: false,
    };
  },
  methods: {
    async getSourceCodeList() {
      const res = await getSourceCodeList();
      this.sourceCodeList = res.data.slice(0, 4);
    },
    downloadResource(e) {
      this.dialogVisible = true;
      this.keyName = e.subjectName;
      this.qrCodeurl = e.downUrl;
      this.officialName = e.officialName;
    },
  },
  created() {
    this.getSourceCodeList();
  },
};
</script>

<style scoped lang='less'>
.source-code {
  width: 571px;

  .code {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    box-sizing: border-box;

    &:hover {
      background: #f5fcff;

      .download {
        background: #018dd6;
        color: #ffffff;
      }
    }

    img {
      width: 60px;
      height: 60px;
      margin-right: 15px;
    }

    .wrap {
      width: 456px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .description {
      h3 {
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 298px;
      }

      p {
        max-width: 328px;
        width: 328px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 19px;
        font-size: 14px;
        color: #666666;
        margin-top: 10px;
      }
    }

    .download {
      width: 80px;
      height: 30px;
      border: 1px solid #018dd6;
      font-weight: 500;
      color: #018dd6;
      font-size: 14px;
      border-radius: 4px;
      text-align: center;
      line-height: 30px;
      cursor: pointer;
    }
  }
}

.el-dialog__header {
  display: none;
}

.el-dialog__body {
  padding: 0;
  padding-top: 20px;
}

.direction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f6f6f6;

  .code {
    width: 133px;
    height: 133px;
    margin: 24px 0;
    margin-left: 37px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  img {
    width: 16px;
    height: 14px;
  }

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    border: 1px solid #999999;
    padding: 15px 20px;

    &:last-child {
      margin-right: 37px;
    }

    .content {
      p {
        font-size: 16px;
        font-weight: 400;
        color: #333333;

        &:nth-child(2) {
          font-size: 20px;
          font-weight: bold;
          color: #000000;
        }
      }
    }
  }
}
</style>