<template>
  <ul class="teacher">
    <!--     <li v-for="(item, index) in teacherArr" :key="index" class="item_box">
      <el-avatar :src="item.photo" alt="大数据视频教程"
        style="width: 80px; height: 80px; border-radius: 50%; overflow: hidden"
        :shape="'circle'"></el-avatar>
      <div class="introduce">
        <h2 v-text="item.teacherName"></h2>
        <el-tag size="mini" style="color: #4c8bdc" v-cloak>{{ item.courseNum
        }}<span style="color: #000">节精品课</span>
        </el-tag>
        <p v-text="item.title.split(' ')[0]"></p>
        <p v-text="item.title.split(' ')[1]"></p>
      </div>
      <el-tag class="downloadBtn" @click="skipTechnical(index)">教程下载</el-tag>
    </li> -->
    <li v-for="(item, index) in teacherArr" :key="index" class="item_box">
      <el-avatar :src="item.img_url" alt="大数据视频教程"
        style="width: 80px; height: 80px; border-radius: 50%; overflow: hidden;display: flex;justify-content: center;"
        :shape="'circle'" class="avatar"></el-avatar>
      <div class="introduce">
        <h2 v-text="item.fname"></h2>
        <el-tag size="mini" style="visibility: hidden;" v-cloak>
        </el-tag>
        <p v-text="item.position.split('，')[0]"></p>
        <p v-text="item.original_position"></p>
      </div>
      <el-tag class="downloadBtn" @click="skipTechnical(item.cou)">教程下载</el-tag>
    </li>
  </ul>
</template>

<script>
// import { getTeachers } from "network/home";
import axios from 'axios'
import md5 from 'js-md5';

export default {
  name: "TeacherList",
  data() {
    return {
      teacherArr: [],
    };
  },
  methods: {
    /*     async getTeachers() {
          const res = await getTeachers();
          this.teacherArr = res.data;
        }, */
    getTeachers() {
      const params = {
        rows: '6',
        timestamp: parseInt(new Date().getTime() / 1000) + '',
      }
      const sign = md5(JSON.stringify(params) + '3a8922a32911e4113a634a3bd2e20ab5')
      params.sign = sign

      const formdata = new FormData()
      Object.keys(params).forEach(v => {
        formdata.set(v, params[v])
      })

      axios({
        url: 'https://oj-api.1000phone.com/index.php/Api/aLecturerList',
        method: 'post',
        contentType: "application/x-www-form-urlencoded",
        data: formdata
      }).then(res => {
        this.teacherArr = res.data.data.slice(0, 6)
      })
    },
    //跳转页面,使用session保存对应课程参数subjectId
    skipTechnical(i) {
      let id = 0;
      // 将oa学科id转化为视频站学科id
      switch (i) {
        case 27:
          id = 1; // java
          break;
        case 13:
          id = 3; // web
          break;
        case 2:
          id = 12; // 大数据
          break;
        case 52:
          id = 13; // 软件测试
          break;
        case 4:
          id = 4; // python
          break;
        case 51:
          id = 8; // 云计算
          break;
        case 68:
          id = 2; // 物联网
          break;
        default:
          id = 1;
      }
      // 将序号转换为subjectId
      /*       switch (i) {
              case 0:
                id = 1; // java
                break;
              case 1:
                id = 3; // web
                break;
              case 2:
                id = 12; // 大数据
                break;
              case 3:
                id = 13; // 软件测试
                break;
              case 4:
                id = 4; // python
                break;
              case 5:
                id = 8; // 云计算
                break;
              default:
                id = 1;
            } */
      sessionStorage.setItem("subjectId", id);
      let routeUrl = this.$router.resolve({
        path: "/classify",
        query: this.$route.query,
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  created() {
    this.getTeachers();
  },
};
</script>

<style scoped lang='less'>
.teacher {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .item_box {
    position: relative;
    width: 375px;
    height: 100px;
    padding: 10px 15px;
    display: flex;
    background: #ffffff;
    border-radius: 10px;
    margin-top: 16px;

    .introduce {
      margin-left: 10px;
      padding-top: 9px;

      h2 {
        float: left;
        font-size: 16px;
        margin-right: 8px;
        line-height: 17px;
        height: 17px;
        max-width: 152px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      span.el-tag--mini {
        font-size: 12px;
        padding: 0 10px;
        background-color: #d9e9ff;
        height: 17px;
        line-height: 16px;
        transform: translateY(-1px);
      }

      p {
        font-size: 12px;
        max-width: 170px;
        line-height: 18px;
        color: #666;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-of-type {
          margin-top: 12px;
        }
      }
    }

    .downloadBtn {
      position: absolute;
      bottom: 10px;
      right: 15px;
      width: 72px;
      height: 22px;
      padding: 0 11px;
      background: linear-gradient(90deg, #ffa800, #ff2b5d);
      border-radius: 11px;
      font-size: 12px;
      color: #fff;
      line-height: 20px;
      cursor: pointer;
      border: none;
      /*           &:hover {
            background: linear-gradient(180deg, #ff2b5d,#ffa800 );
            // transition: all 0.3s ease;
          } */
    }
  }
}
</style>